body {
  font-family: "Exo 2", sans-serif;
  background-color: #1c4dac;
  margin: 0;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
}
